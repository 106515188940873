<template v-slot:no-data>
	<v-card>
		<s-toolbar label="Armar Parihuela Calibrado" dark color="#8e8f91"></s-toolbar>
		<v-container>		
			<v-row>
				<v-col cols="6" md="6" lg="3">
					<s-select-definition
						clearable
						:def="1173"
						label="Cultivo"
						v-model="TypeCultive"
					/>
				</v-col>
				
				<v-col cols="6" md="6" lg="3">
					<s-select-variety
						clearable
						@input="intitialize()"
						:cultiveID="TypeCultive"
						label="Variedad"
						full
						v-model="VrtID"
					/>
				</v-col>
			</v-row>					
			<v-row >
				<v-col cols="12" md="6">
					<s-toolbar
						dark
						:color="'#BAB6B5'"
						style="display: flex"							 
						label="Parihuelas FSMA"
					></s-toolbar>
					<v-data-table
						:items="itemsSend"
						item-key="LlpID"
						dense
						:headers="headerSend"
						:items-per-page="15"									 								
						disable-sort
						v-model="selectedItemsSend"								
						show-select
					> 		
						<template v-slot:item.LlpID="row">
							<v-chip
								small
								dark											 
								:color="row.item.StatusM != 0 ? 'success' : 'warning'"
							>
								{{row.item.LlpID}}
							</v-chip>
						</template>					
						<template v-slot:item.StatusM="row">
							<v-chip
								small
								dark											 
								:color="row.item.StatusM != 0 ? 'success' : 'warning'"
							>
								Estado
							</v-chip>
						</template>
					<!--@click:row="rowClickSend"-->
						<template v-slot:item.Tranfer="props">
							<v-tooltip top allow-overflow color="primary">
								<!-- fab border-radius: 13px; fa-exchange-alt group-by="TypeDestinyName"-->
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										x-small
										:color="'info'"
										fab
										style="margin: 6px 6px 6px 6px;"
											
										v-bind="attrs"
										v-on="on"
										>
										<v-icon class="fas fa-arrow-circle-up"></v-icon>
									</v-btn>
								</template>
								<span>Transferir</span>
							</v-tooltip>
							
						</template>
						<template v-slot:item.delete="props">
									<v-btn
										x-small
										:color="'error'"
										fab
										style="margin: 6px 6px 6px 6px;"
										@click="deleteParihuela(props.item)"
										>
										<v-icon
											style="font-size: 16px !important">
											fas fa-times</v-icon>
									</v-btn>										
						</template>
						<template v-slot:item.RcfID="row">
							<v-chip
								small
								dark
								outlined
								:color="'red'"
							>
								{{row.item.RcfID}}
							</v-chip>
						</template>	
					</v-data-table>
				</v-col>
				<v-col cols="12" md="6">
					<s-toolbar
						dark
						:color="'#BAB6B5'"
						style="display: flex"							 
						label="Parihuela para Transferencia"
					></s-toolbar>
					<v-data-table
						:items="itemsSendTwo"
						item-key="LlpID"
						dense
						:headers="headerSend"
						:items-per-page="15"									 								
						disable-sort
						v-model="selectedItemsSendTwo"								
						show-select
					> 			
					<template v-slot:item.LlpID="row">
							<v-chip
								small
								dark											 
								:color="row.item.StatusM != 0 ? 'success' : 'warning'"
							>
								{{row.item.LlpID}}
							</v-chip>
						</template>					
						<template v-slot:item.StatusM="row">
							<v-chip
								small
								dark											 
								:color="row.item.StatusM != 0 ? 'success' : 'warning'"
							>
								Estado
							</v-chip>
						</template>
					<!--@click:row="rowClickSend"-->
						<template v-slot:item.Tranfer="props">
							<v-tooltip top allow-overflow color="primary">
								<!-- fab border-radius: 13px; fa-exchange-alt group-by="TypeDestinyName"-->
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										x-small
										:color="'info'"
										fab
										style="margin: 6px 6px 6px 6px;"
											
										v-bind="attrs"
										v-on="on"
										>
										<v-icon class="fas fa-arrow-circle-up"></v-icon>
									</v-btn>
								</template>
								<span>Transferir</span>
							</v-tooltip>
							
						</template>
						<template v-slot:item.delete="props">
									<v-btn
										x-small
										:color="'error'"
										fab
										style="margin: 6px 6px 6px 6px;"
										@click="deleteParihuela(props.item)"
										>
										<v-icon
											style="font-size: 16px !important">
											fas fa-times</v-icon>
									</v-btn>										
						</template>
						<template v-slot:item.RcfID="row">
							<v-chip
								small
								dark
								outlined
								:color="'red'"
							>
								{{row.item.RcfID}}
							</v-chip>
						</template>	
					</v-data-table>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12">
						<s-toolbar
						dark
						:color="'#BAB6B5'"
						style="display: flex"							 
						transferir									 
						@transferir="view()"
					></s-toolbar>
					<v-data-table
						:items="itemsSendEnd"
						item-key="LlpID"
						dense
						:headers="headerSendEnd"
						:items-per-page="15"									 								
						disable-sort
						v-model="selectedItemsSend"								
						show-select
					> 		
					<template v-slot:item.separator="row">
							<v-chip
								small
								dark											 
								color="success"
							>
								_
							</v-chip>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>

		<v-dialog
			v-if="openDialog"
			v-model="openDialog"
			persistent
			transition="dialog-bottom-transition"
			fullscreen
		>
			<supply-pallet 
			:nroPallet="PalletNro" 
			:typeCaliber="typeCaliber" 
			:typeCrop="TypeCrop" 
			:itemPalletOne="itemPalletOne" 
			:TypeCultive="TypeCultive"
			@close="close()">
			</supply-pallet>
		</v-dialog>

		<v-dialog
			v-if="openTranfer"
			v-model="openTranfer"
			persistent
			transition="dialog-bottom-transition"
		>
			<tranfer-pallet :value="itemsTranfer" @close="closeTransfer()">

			</tranfer-pallet>
		</v-dialog>

		<v-row justify="center" cols="12" style="display: none">
			<v-col cols="12" v-for="item in selectedItemsSend">
				<qr-code
					:text="
						item.LlpID +
							', Jabas: ' +
							item.LlpQuantityJaba +
							',' +
							item.TypeCaliberName +
							',' +
							item.TypeCultiveName +
							',' +
							item.VrtName +
							',' +
							item.TypeDestinyName +
							',' +
							item.TypeDestiny
					"
					error-level="H"
					hidden
				>
				</qr-code
				><br />
			</v-col>
		</v-row>
		<v-dialog
			v-if="openDialogIndex"
			v-model="openDialogIndex"
			persistent
			width="60%"
		>
			<supply-pallet-index @click="click($event)"></supply-pallet-index>
		</v-dialog>

		<v-dialog
			v-if="openViewQR"
			v-model="openViewQR"
			width="40%"
		>
			<prf-viewqr :selectedItemsSend="selectedItemsSend" @click="openViewQR = false"></prf-viewqr>
		</v-dialog>

       
	</v-card>
</template>
<script>
	//Service
	import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";
	import _sLotProdCalibratedPrintQRService from "../../../services/FreshProduction/PrfLotProductionCalibratedQRService";
	import SupplyPallet from "./SupplyPalletNew.vue";
	import TranferPallet from './TranferPallet.vue';
	import SupplyPalletIndex from './SupplyPalletIndexMirror.vue';
	import PrfViewqr from './PrfViewqr.vue';
	import qrCode from "vue-qr-generator";
	import signalr from "signalr";
	import ServiceScaffold from '@/services/FreshProduction/ConfigFresh/ScaffoldService'
	import _sCreatePalletTempService from '@/services/FreshProduction/PrfCreatePalletTempService'
	import _sGenParam from "@/services/General/ParameterService";
	import _sSupplyPalletMirror from "@/services/FreshProduction/PrfFsmaChangeService";

	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
 

	export default {
		components: { SupplyPallet, TranferPallet, qrCode, SupplyPalletIndex, PrfViewqr, SSelectVariety },
		data: () => ({

			VrtID : null,
			VrtIDBk : null,
			TypeCultive : 1,

			CptIDTwo: 0,
			changeItemPallet: {},
			Scaffolds: [],
			TypeCultive:0,
			itemsSendDetail: [],

			selectedItemsSend: [],
			selectedItemsSendTwo: [],

			itemsPallet: [],
			openDialog: false,
			openTranfer: false,
			openViewQR:false,
			PalletNro: 0,
			typeCaliber: 0,
			textQR: "",
			itemsSend: [],
			itemsSendTwo: [],
			itemsSendEnd: [],
			headerSend: [
				/*{ text: "Tranferir", value: "Tranfer" },*/
				 
				{ text: "Id Pallet", value: "LlpID" },
				{ text: "Lote Prod", value: "RcfID" },
				{ text: "Tarima", value: "ScfCode" },
				
				{ text: "Certificaciones", value: "Certifications", width: 10 },
				{ text: "Cal", value: "TypeCaliberName" },
				{ text: "# JAB", value: "LlpQuantityJaba" },
				{ text: "Tipo Cultivo", value: "TypeCropName" },
				/*{ text: "Cultivo", value: "TypeCultiveName" },*/
				{ text: "Var", value: "VrtName" },
				{ text: "Peso (KG)", value: "WeightAverageParihuela" },
				/*{ text: "Destino", value: "TypeDestinyName" },*/
				{ text: "Estado", value: "StatusM" },
			],
			headerSendEnd: [
				/*{ text: "Tranferir", value: "Tranfer" },*/

				{ text: "Tarima", value: "ScfCode" }, 
				{ text: "Id Pallet", value: "LlpID" },
				{ text: "Lote Prod", value: "RcfID" },
				{ text: "Certificaciones", value: "Certifications" },
				{ text: "Cal", value: "TypeCaliberName" },
				{ text: "# JAB", value: "LlpQuantityJaba" },
				{ text: "Peso (KG)", value: "WeightAverageParihuela" },
				

				{ text: " ", value: "separator" },
				

			 	{ text: "Tarima", value: "ScfCodem" },
				{ text: "Id Pallet", value: "LlpIDm" },
				{ text: "Lote Prod", value: "RcfIDm" },
				{ text: "Certificaciones", value: "Certificationsm" },
				{ text: "Cal", value: "TypeCaliberNamem" },
				{ text: "# JAB", value: "LlpQuantityJabam" },
				{ text: "Peso (KG)", value: "WeightAverageParihuelam" },
				
			],
			itemsTranfer: {},
			executeQR: false,
			WbbNetWeight: 0,

			openDialogIndex: true,
			TypeCrop: 1,
			TypeAreaAssignPerson: 0,
			TypeAreaGeneric: 0,
			itemPalletOne: {},
			itemPallet: {},
			itemsCaliber: [],
			maxNumberGirdle:0,
			ItemsNumberGirdle:[],
            showModalMirror: false,
			itemsPalletChange: [],
		}),

		 
		watch: {
			selectedItemsSend(){
				this.showItemsSend();
			},
			selectedItemsSendTwo(){
				this.showItemsSend();
			 }
		},

		methods: {
			 
			showItemsSend(){
				//this.selectedItemsSend 
				//this.selectedItemsSendTwo 4

				console.log("this.selectedItemsSend ", this.selectedItemsSend);
				console.log("this.selectedItemsSendtwo ", this.selectedItemsSendTwo);

				this.itemsSendEnd = [];

				for (let index = 0; index < this.selectedItemsSend.length; index++) {
					const element = this.selectedItemsSend[index];
					if (this.selectedItemsSendTwo.length == this.selectedItemsSend.length) {

						console.log("send ", this.selectedItemsSend[index].LlpID);
						console.log("send two", this.selectedItemsSendTwo[index].LlpID);

						let indexm = (this.selectedItemsSend.length - index) - 1;

						 this.itemsSendEnd.push({ 
							RcfID: element.RcfID,							
							ScfCode: element.ScfCode,							
							LlpID: element.LlpID,
							Certifications: element.Certifications,
							TypeCaliberName: element.TypeCaliberName,
							LlpQuantityJaba: element.LlpQuantityJaba,
							WeightAverageParihuela: element.WeightAverageParihuela,
							
							RcfIDm: this.selectedItemsSendTwo[indexm].RcfID,
							ScfCodem: this.selectedItemsSendTwo[indexm].ScfCode,
							LlpIDm: this.selectedItemsSendTwo[indexm].LlpID,
							Certificationsm: this.selectedItemsSendTwo[indexm].Certifications,
							TypeCaliberNamem: this.selectedItemsSendTwo[indexm].TypeCaliberName,
							LlpQuantityJabam: this.selectedItemsSendTwo[indexm].LlpQuantityJaba,
							WeightAverageParihuelam: this.selectedItemsSendTwo[indexm].WeightAverageParihuela,
					 });
					}
				}
				 
			},
           
			 
			click(val) {
				
				this.TypeCrop = parseInt(val.DedAbbreviation);
				this.TypeAreaGeneric = val.DedValue;

				this.intitialize();
				this.openDialogIndex = false;
			},

			intitialize() {
				
				this.itemsSend = []
				this.itemsSendTwo = []
				 
				 if(this.VrtID == null || this.VrtID == 0){
					return;
				 }
				 
				 if(this.VrtID == this.VrtIDBk){
					console.log("VrtIDBk",this.VrtIDBk)
					return;
				 }

				 this.VrtIDBk = this.VrtID;

				_sLotProdCalibratedService
					.GetPalletSendM({
						PrsID: this.$fun.getUserInfo().PrsID, 
						TypeCrop: this.TypeCrop, 
						IsRework: 0,
						IsFsma: 1,
						TypeDestiny: 2,		
						VrtID: this.VrtID,				 
						}, this.$fun.getUserID())
					.then(r => {

						console.log("pallet send r.data", r.data);
						console.log("pallet send r.data fecha", new Date());

						if (r.data.length > 0) {
							//this.itemsSend = r.data.filter(x => x.StatusM.includes("FSMA") > 1 );
							this.itemsSend = r.data.filter(x => x.StatusM == 0 && x.StatusFsm == 0);
							//this.itemsSendTwo = r.data.filter(x => x.StatusM == 0 && x.StatusFsm != 0);
							//this.itemsSend = r.data;

						}
						
						_sLotProdCalibratedService
							.GetPalletSendM({
								PrsID: this.$fun.getUserInfo().PrsID, 
								TypeCrop: this.TypeCrop, 
								IsRework: 0,
								IsFsma: 0,
								TypeDestiny: 2,		
								VrtID: this.VrtID,					 
								}, this.$fun.getUserID())
							.then(re => {
								
								if (re.data.length > 0) {
									//this.itemsSend = r.data.filter(x => x.StatusM.includes("FSMA") > 1 );
									//this.itemsSend = r.data.filter(x => x.StatusM == 0 && x.StatusFsm == 0);
									this.itemsSendTwo = re.data.filter(x => x.StatusM == 0 && x.StatusFsm != 0);
									//this.itemsSend = r.data;
								}
							});				 
					});	

				
			},
  

			close() {
				this.openDialog = false;
				this.executeQR = false
				this.intitialize();
			},

			encodeBase64(img)
			{
				var canvas = document.createElement("canvas");
				canvas.width = img.width;
				canvas.height = img.height;
				var ctx = canvas.getContext("2d");
				ctx.drawImage(img, 0, 0);
				var dataURL = canvas.toDataURL();
				return dataURL;
			},
     
			printQR() {
				
				if(this.itemsSend.length > 0) {

				

				var imgAll = document.querySelector("img");

				var daten = new Date().toLocaleString();

				var base6 = this.encodeBase64(imgAll);

				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-left: 4cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
				);

				
				/* imgAll.forEach(element => { */
					pwa.document.write(
						"<div class='col col-4' style='margin: 20px 20px 20px 0px;'><img alt='Scan me!' style='display: block;'  src='" +
							base6 +
							"' /></div>",
					);

					pwa.document.write("<br><br><div ><table border='0'>");
					pwa.document.write(
								"<tr><td colspan='4'>Datos Generales: " + 
								"<tr>" +
								"<td>" + "<b>"+this.selectedItemsSend[0].LlpID + "</b>"+
								"</td><td> || " + "<b>"+this.selectedItemsSend[0].TypeCultiveName + "</b>"+
								"</td><td> || " + "<b>"+this.selectedItemsSend[0].VrtName + "</b>"+
								"</td><td> || " + "<b>"+this.selectedItemsSend[0].TypeCropName + "</b>"+
								"</td><td> || " + "<b>"+this.selectedItemsSend[0].TypeDestinyName + "</b>"+
								"</td><td> || " + "<b>"+this.selectedItemsSend[0].TypeCaliberName + "</b>"+
								/* "</td><td> || " + "<b>" + this.WbbNetWeight.toFixed(2)+"</b>"+ */
								" </td></tr>",
					);
					pwa.document.write("</table></div>");

					this.itemsSendDetail.forEach(detail => {
							
							pwa.document.write("<div ><table border='0'>");

							pwa.document.write(
								"<tr ><td colspan='3'>Lote Produccion: " + detail.RcfID +", Jaba: " + detail.LppQuantityJaba  +", Kg: " + detail.WbbNetWeight.toFixed(2) +"</td></tr>"
							);

							pwa.document.write(
								"<tr><td>Productor: </td><td>" + detail.PrdCardName +"</td></tr>" +
								"<tr><td>Fundo: </td><td>" + detail.FagName + " || "+ detail.FltName + " || "+ detail.FltCodSenasa + "</td></tr>" + 
								"<tr><td>Procedencia: </td><td>" + detail.ZonName + " - " + detail.TypeZonLocationName + "</td></tr>"
							);

							pwa.document.write(
								"<tr><td>Certificaciones: </td><td>" + detail.TcDescription +"</td></tr>",
							);
							pwa.document.write(
								"<tr><td>Posición: </td><td>" + detail.LppPosition +"</td></tr>",
							);

							pwa.document.write(
								"<tr><td>Fecha Impresión: </td><td>" + daten +"</td></tr>",
							);
							

							pwa.document.write("</table>-------------------------------------------------------------</div>");
						});

				/* }); */

				

				pwa.document.write("</div></body></html>");
				pwa.document.close();

				}
			},

			view(){

				console.log("item sellecionados", this.selectedItemsSend);

				if (this.selectedItemsSend.length <= 0) {
					this.$fun.alert("Seleccione un Pallet","warning");
					return;
				}

				if (this.selectedItemsSend.length != this.selectedItemsSendTwo.length) {
					this.$fun.alert("el numero de Pallet seleccionados no coinciden","warning");
					return;
				}

				 this.$fun.alert("¿Quieres cambiar los pallet?","question")
				 .then((r) => {
					if (r.value) {
						_sSupplyPalletMirror.save({
							PalletsOne: this.selectedItemsSend,
							PalletsTwo: this.selectedItemsSendTwo,
							FsAction: 1
							}, this.$fun.getUserID())
						.then((r) => {
							this.itemsSend =  [];
							this.itemsSendTwo = [];
							this.selectedItemsSend = [];
							this.selectedItemsSendTwo = [];

							this.$fun.alert("Pallet cambiado correctamente","success");



							 this.intitialize();

						});
					}
				 });
			}

		},

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.SendMessageSendParihuela = group => {
					this.intitialize();
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "updateWaitingsendparihuela");
				});
			});
 
		},
	};
</script>